<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/is.png" alt="">
			<div class="text">
				<p class="title">智慧安防（家庭版）</p>
				<p class="subTitle">Intelligent Security</p>
			</div>
		</div>
		
		<div class="overview">
			<div class="text">
				<div class="line"></div>
				<p class="title">概述</p>
				<p class="subTitle">summary</p>
				<p class="content">随着智能家居和智慧社区安防需求的凸显，智能安防⾯临新的发展契机，同时，由于智能安防⾏业的特殊性，国家对智能安防产业的发展⼀直都给予政策上的⿎励。此外，⼈们对家庭安全和财产的重视程度也越来越⾼，越来越多的家庭选择在家里安装智能家庭安防设备，保障居家安全。</p>
			</div>
			<img class="overview-img" src="../../assets/imgs/is-overview.png" alt="">
		</div>
		
		<div style="background-color: rgb(245, 245, 245);text-align: center;">
			<img class="plan-img" src="../../assets/imgs/is-plan.png" alt="">
		</div>
		
		<div class="system">
			<div class="line"></div>
			<p class="title">系统组成</p>
			<p class="subTitle">system composition</p>
			<div class="system-img">
				<div class="sys-content" v-for="item of systemArr" :key="item.id">
					<img :src="item.img" alt="">
					<p class="name">{{item.name}}</p>
				</div>
			</div>
		</div>
		
		<div style="background: #F5F5F5;">
			<div class="trait">
				<div class="line"></div>
				<p class="title">系统功能及技术特点</p>
				<p class="subTitle">System functions and technical features</p>
				<div class="swiper">
					<div ref="mySwiper" class="swiper-container" id="traitSwiper"  >
					  <div class="swiper-wrapper">
					    <div class="swiper-slide my-swiper-slide" v-for="item of traitArr" :key="item.id">
							<img :src="item.img" alt="">
						</div>
					  </div>
					 <!--导航器-->
					 
					</div>
					<div class="swiper-button-prev"></div>
					<div class="swiper-button-next"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import "swiper/css/swiper.css";
	export default {
		data() {
			return {
				systemArr: [
					{
						id: '1',
						name: '全屋安防套装',
						img: require('@/assets/imgs/isSystem1.png')
					},
					{
						id: '2',
						name: '亲情关怀安防套装',
						img: require('@/assets/imgs/isSystem2.png')
					},
					{
						id: '3',
						name: '厨房安防套装',
						img: require('@/assets/imgs/isSystem3.png')
					}
				],
				traitArr:[
					{
						id: '1',
						title: '组网方式',
						subTitle: 'WIFI＋ZigBee自组网',
						img: require('@/assets/imgs/isTra1.png')
					},
					{
						id: '2',
						title: '操作方式',
						subTitle: '智能手机APP远程操作，同时设安防模式一键式操作，简单快捷。',
						img: require('@/assets/imgs/isTra2.png')
					},
					{
						id: '3',
						title: '可以实现的场景模式',
						subTitle: '离家模式、睡眠模式、安防模试（可自动开启）等。',
						img: require('@/assets/imgs/isTra3.png')
					},
					{
						id: '4',
						title: '系统可以实现的功能',
						subTitle: '手机远程开家门、门窗远程关检测、远程监控、紧急救助、远程视频交流、防盗报警、煤气泄漏检测、空气质量自动调控',
						img: require('@/assets/imgs/isTra4.png')
					},
				]
			}
		},
		
		methods: {
			initSwiper(){
			  // eslint-disable-next-line
			  let vueComponent=this//获取vue组件实例
			  //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
			  this.currentSwiper = new Swiper('#traitSwiper', {
				
				autoHeight:'true',//开启自适应高度,容器高度由slide高度决定
				//导航器
				navigation: {
				  nextEl: '.swiper-button-next',
				  prevEl: '.swiper-button-prev',
				},
			   
				slidesPerView: 3, //视口展示slide数1
				spaceBetween: 25	
			  })
					
			}
		},
		
		mounted() {
			this.initSwiper()
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}
.abstract .text {
	position: absolute;
	top: 50%;
	right: 24%;
	transform: translate(0,-50%);
}
.abstract .text .title {
	width: 360px;
	height: 40px;
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}
.abstract .text .subTitle {
	width: 164px;
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 20px;
}

.overview {
	width: 1200px;
	margin: 0 auto;
	padding: 100px 136px 114px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
}

.overview-img {
	width: 386px;
}

 .line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}
.overview .text .title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}
.overview .text .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;	
	text-transform: uppercase;
	margin-top: 5px;
}
.overview .text .content {
	width: 490px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #666666;
	line-height: 24px;
	margin-top: 40px;
}

.plan-img {
	width: 1200px;
}

.system {
	width: 1200px;
	margin: 0 auto;
	padding: 50px 136px 87px;
	box-sizing: border-box;
}

.system .title,.trait .title{
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	margin: 12px 0 5px;
}

.system .subTitle,.trait .subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
}

.system .system-img {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 45px;
}

.system .system-img .sys-content {
	width: 270px;
	height: 340px;
	position: relative;
}

.system .system-img .sys-content img {
	width: 100%;
}

.system .system-img .sys-content .name {
	position: absolute;
	left: 50%;
	bottom: 53px;
	transform: translate(-50%,0);
	height: 18px;
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
	white-space: nowrap;
}

.trait {
	width: 1200px;
	margin: 0 auto;
	padding: 50px 136px 70px;
	box-sizing: border-box;
}

.trait .swiper {
	text-align: center;
	position: relative;
	margin-top: 74px;
}

.trait .swiper .swiper-container {
	height: 220px;
	width: 768px;
}

.trait .swiper .swiper-container .swiper-slide {
	height: 220px;
	width: 242px;
	background-color: #fff;
	box-sizing: border-box;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
}

.swiper-button-prev::after {
	display: none;
}

.swiper-button-prev {
	width: 48px;
	height: 48px;
	background-image: url('../../assets/imgs/left.png');
	background-size: cover;
	top: 50%;
	left: 10px;
	transform: translate(0,-50%);
}

.swiper-button-next::after {
	display: none;
}

.swiper-button-next {
	width: 48px;
	height: 48px;
	background-image: url('../../assets/imgs/right.png');
	background-size: cover;
	top: 50%;
	right: 10px;
	transform: translate(0,-50%);
}


</style>